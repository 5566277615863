import React from 'react';

export default function EmailConfirmation() {
  return (
    <>
      <h2 className="font-rp-gelica-light leading-normal text-28 max-w-295 d:text-40px d:w-full d:max-w-none">
        Confirmed
      </h2>

      <p className="font-rp-pn-regular leading-6 text-base">
        You&apos;re on your way to recharge. Check your email for your exclusive discount.
      </p>
    </>
  );
}
