import { useEvents } from '@events/EventsProvider';
import { useEffect } from 'react';

type EventProperties = {
  validation?: boolean;
  flagKey: string;
  variant: string | null;
};

export default function useSendAmplitudeExposure({
  flagKey,
  variant,
  validation,
}: EventProperties) {
  const { amplitude } = useEvents();

  useEffect(() => {
    if (validation && variant && amplitude) {
      amplitude
        .track(
          {
            event_type: '$exposure',
          },
          {
            flag_key: flagKey,
            variant,
          },
        )
        .promise.catch();
    }
  }, [amplitude, flagKey, variant, validation]);
}

useSendAmplitudeExposure.defaultProps = {
  validation: true,
};
