import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { sendEmailToIterable } from '@utils/services';
import { isAValidEmail } from '@helpers/emailValidation';
import toggleErrorPopup from '@helpers/toggleErrorPopup';
import { store } from '@context/store';
import { capitalize } from 'lodash';
import { setCookie } from '@helpers/cookies';

type Props = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>;
};

export default function EmailCollectionForm({ setCurrentStep, setIsFormSubmitted }: Props) {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    if (!email || !isAValidEmail(email)) {
      return;
    }

    try {
      await sendEmailToIterable(email);

      setCookie('mails_opt_in', 'true', 30);
      setIsFormSubmitted(true);
      setCurrentStep(2);
    } catch (error: any) {
      toggleErrorPopup(
        capitalize(error.response.data.errors[0].msg) || 'Email is not valid',
        dispatch,
      );
    }
  };

  return (
    <>
      <h2 className="font-rp-gelica-light leading-normal text-28 max-w-295 d:text-40px d:w-full d:max-w-none">
        Recharge in just one day with your first pass free.
      </h2>

      <p className="font-rp-pn-regular leading-6 text-base">
        Take a mid-week break and get back to the best version of yourself. Terms and Conditions
        apply.
      </p>

      <div
        className="bg-white flex justify-between rounded-xl border border-rp-gray-divider d:w-90"
        style={{ padding: '2px 2px 2px 14px' }}
      >
        <input
          className="mr-4 outline-none text-base text-rp-disabled w-full"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email address *"
        />

        <button
          type="button"
          onClick={handleSubmit}
          className="bg-rp-primary flex-shrink-0 font-rp-pn-semi-bold text-base rounded-xl"
          style={{ width: '87px', height: '44px' }}
        >
          Get Offer
        </button>
      </div>
    </>
  );
}
