import React, { useContext, useMemo } from 'react';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { store } from '@context/store';
import RoutingPath from '@constants/routingPath';
import { useRouter } from 'next/router';

export default function EmailThankYou() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { userLocation } = state;
  const router = useRouter();

  const spaSRPLink = useMemo(() => {
    if (userLocation) {
      const { name, id } = userLocation;
      const cityName = name.split(',')[0];
      return `${RoutingPath.SRP}/${cityName}-${id}`;
    }

    return `${RoutingPath.SRP}/Los-Angeles-4`;
  }, [userLocation]);

  const handleOnClick = () => {
    router.push(spaSRPLink);
  };

  return (
    <>
      <h2 className="font-rp-gelica-light leading-normal text-28 max-w-295 d:text-40px d:w-full d:max-w-none">
        Recharge with just one day off.
      </h2>

      <p className="font-rp-pn-regular leading-6 text-base">
        Take a mid-week break and get back to the best version of yourself.
      </p>

      <ButtonPrimary onClick={handleOnClick}>Search Near You</ButtonPrimary>
    </>
  );
}
